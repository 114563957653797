import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaderShow: 0,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    // actions
    showLoader: (state) => {
      state.loaderShow = 1;
    },
    hideLoader: (state) => {
      state.loaderShow = 0;
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
