import { configureStore } from "@reduxjs/toolkit";
import offCanvasReducer from "../features/offCanvasSlice";
import loaderReducer from "../features/loaderSlice";
import showContentReducer from "../features/showContentSlice";
import showAccountFormReducer from "../features/showAccountFormSlice";
import reloadDataTableReducer from "../features/reloadDataTableSlice";
import operatingHoursReducer from "../features/operatingHoursSlice";
import userReducer from "../features/userSlice";
import profileReducer from "../features/profileSlice";
import eventBaysReducer from "../features/eventBaysSlice";
import bayReducer from "../features/baysSlice";
import eventAreasReducer from "../features/eventAreasSlice";
import {enableBatching} from "redux-batched-actions";
export const store = configureStore({
  reducer: {
    offcanvas: offCanvasReducer,
    loader: loaderReducer,
    accountForm: showAccountFormReducer,
    datatableReload: reloadDataTableReducer,
    showContent: showContentReducer,
    operatingHours: operatingHoursReducer,
    userValues: userReducer,
    profile: profileReducer,
    eventBays: enableBatching(eventBaysReducer),
    eventAreas: eventAreasReducer,
    bays: bayReducer,
  },
});
