import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showAccountForm: 1,
};

export const showAccountFormSlice = createSlice({
    name: 'accountForm',
    initialState,
    reducers: {
      showForm: (state) => {
        state.showAccountForm = 1;
      },
      hideForm: (state) => {
        state.showAccountForm = 0;
      },
    },
  
  });
  
  export const { showForm, hideForm } = showAccountFormSlice.actions;
  
  
  export default showAccountFormSlice.reducer;
  