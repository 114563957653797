import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reload: 0,
};

export const reloadDataTableSlice = createSlice({
  name: "reloadDataTable",
  initialState,
  reducers: {
    reloadTable: (state) => {
      state.reload === 0 ? (state.reload = 1) : (state.reload = 0);
      // state.reload = 1;
    },
    resetReloadTable: (state) => {
      state.reload = 0;
    },
  },
});

export const { reloadTable, resetReloadTable } = reloadDataTableSlice.actions;

export default reloadDataTableSlice.reducer;
