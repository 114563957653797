import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAreas: [],
};
export const eventAreasSlice = createSlice({
  name: "eventAreas",
  initialState,
  reducers: {
    addAreas: (state, action) => {
      if (action.payload) {
        if (action.payload.checked) {
          if (state.allAreas.length > 0) {
            const bayList = state.allAreas?.map((item) => {
              return item.value;
            });
            if (!bayList.includes(action.payload.value)) {
              state.allAreas.push(action.payload);
            }
          } else {
            state.allAreas.push(action.payload);
          }
        } else {
          state.allAreas = state.allAreas.filter((bay) => {
            return bay.value != action.payload.value;
          });
        }
      }
    },

    removeAllAreas: (state, action) => {
      return {
        ...state,
        allAreas: [] // Create a new state object with allValue set to an empty array
      };
    }
  },
});



const items = (state) => state.eventAreas.allAreas;
export const areaGuid = createSelector([items], (items) => {
  return items?.map((value) => {
    return value.value;
  });
});
export const areaNumbers = createSelector([items], (items) => {
  const groupedBays = _.groupBy(items, function (b) {
    return b.floor;
  });
  const areaNames = {};
  Object.keys(groupedBays).map((floor) => {
    const tempArray = [];
    groupedBays[floor].map((area) => {
      return tempArray.push(area.areaName);
    });
    areaNames[floor] = tempArray;
  });
  return areaNames;
});


export const { addAreas,removeAllAreas } = eventAreasSlice.actions;
export default eventAreasSlice.reducer;
