import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offCanvasShow: 0,
  profile: 0,
  
};



export const offCanvasSlice = createSlice({
  name: 'offcanvas',
  initialState,
  reducers: {
    // actions
    showCanvas: (state) => {
      state.offCanvasShow = 1;
    },
    hideCanvas: (state) => {
      state.offCanvasShow = 0;
    },
    showProfile: (state) => {
      state.profile = 1;
    },
    hideProfile: (state) => {
      state.profile = 0;
    },
  },

});

export const { showCanvas, hideCanvas, showProfile, hideProfile } = offCanvasSlice.actions;


export default offCanvasSlice.reducer;
