
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showContentValue: 0,
    className:"",
    topTitle:""

};



export const showContentSlice = createSlice({
    name: 'showcontent',
    initialState,
    reducers: {
        // actions
        changeContent: (state,action) => {
            state.showContentValue = action.payload.showContentValue;
            state.className = action.payload.className;
            state.topTitle = action.payload.topTitle;
            // console.log(action.payload.showContent)
        },
      

    },

});

export const {changeContent } = showContentSlice.actions;


export default showContentSlice.reducer;
