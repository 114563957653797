//export const API_URL = 'http://localhost/golf-leaderboard/api/auth/';
//export const API_URL = 'https://leaderboardapi.ecsion.com/api/auth/';
//export const API_URL = 'https://leaderboard-laravel.azurewebsites.net/api/auth/';
export const API_URL = process.env.REACT_APP_API_URL;


export const CLASSIC_API_URL = process.env.REACT_APP_CLASSIC_API_GW_URL;
export const DATABLE_CLASSIC_API_URL = process.env.REACT_APP_DATABLE_CLASSIC_API_URL;
export const loginConfig = {
    appId: "f680b28f-3da4-4697-bf33-2fd7e736a06c",
    redirectUri: process.env.REACT_APP_AD_LOGIN_URL,
    scopes: [
        "users.read"
    ],
    authority: 'https://login.microsoftonline.com/28dbe5f4-3a73-4a49-b7bb-0a627a63766b'
}
export const FLITE_API_URL = process.env.REACT_APP_FLITE_API_URL;
export const SIDEBAR_COLOR = process.env.REACT_APP_SIDEBAR_COLOR;
export const HEADER_COLOR = process.env.REACT_APP_HEADER_COLOR;
export const BUTTON_COLOR = process.env.REACT_APP_BUTTON_COLOR;
export const BLACK_LOGO = process.env.REACT_APP_BLACK_LOGO;

export const AZURE_DOMAIN = process.env.REACT_APP_AZURE_DOMAIN;
export const API_URL_UPDATED = process.env.REACT_APP_CLASSIC_API_GW_URL;

export const AZURE_USER_FLOW = process.env.REACT_APP_AZURE_USER_FLOW;
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID;
export const AD_LOGIN_URL = process.env.REACT_APP_AD_LOGIN_URL;
// export const VENUE_GUID = process.env.REACT_APP_VENUE_ID;
export const THEME = process.env.REACT_APP_THEME;

export const FRANCHISE_ID = process.env.REACT_APP_FRANCHISE_ID;
export const SUBVENUE_ID = process.env.REACT_APP_SUBVENUE_ID;
export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;
export const APP_GUID = process.env.REACT_APP_APP_GUID;
export const VENUE_GUID = process.env.REACT_APP_VENUE_GUID;
export const FLITE_API_AZURE = process.env.REACT_APP_FLITE_API_AZURE;
export const IS_LUXE = process.env.REACT_APP_IS_LUXE;
export const BAY_DIRECTION = process.env.REACT_APP_BAY_DIRECTION;

export const APP_LOGO = process.env.REACT_APP_LOGO;

export const COUNT_DOWN_TIMER = 5;

export const appInfo = {
    VenueGUID: VENUE_GUID,
    AppName: APP_NAME,
    AppVersion: APP_VERSION,
    AppGUID: APP_GUID,
}
export const LOGOUT_VARNAME = process.env.REACT_APP_LOGOUT_VARNAME;
export const appSwitcher = {
    'Venue Manager App': process.env.REACT_APP_BAY_APP,
    'Venue Admin App': process.env.REACT_APP_ADMIN_APP,
    'Account Management App': process.env.REACT_APP_EMPLOYEE_MANAGER_APP,
    'Event Management App': process.env.REACT_APP_EVENT_MANAGEMENT_APP,
    'Floor Management App': process.env.REACT_APP_FLOOR_MANAGEMENT_APP,
    "Business Reporting App": process.env.REACT_APP_BUSSINESS_REPORTING_APP,
}
export const bayStates =
    [
        {
            "BayStateId": "1",
            "BayStateName": "Available",
            "FillColor": "#4CAF50",
            "BorderColor": "#4CAF50",
            "LabelColor": "#4CAF50",
            "TextColor": "#ffffff",
            "SortOrder": null
        },
        {
            "BayStateId": "2",
            "BayStateName": "Demo",
            "FillColor": "#C0155C",
            "BorderColor": "#C0155C",
            "LabelColor": "#C0155C",
            "TextColor": "#ffffff",
            "SortOrder": null
        },
        {
            "BayStateId": "3",
            "BayStateName": "Assigned",
            "FillColor": "#C0155C",
            "BorderColor": "#C0155C",
            "LabelColor": "#C0155C",
            "TextColor": "#ffffff",
            "SortOrder": null
        },
        {
            "BayStateId": "4",
            "BayStateName": "In use",
            "FillColor": "#C0155C",
            "BorderColor": "#C0155C",
            "LabelColor": "#C0155C",
            "TextColor": "#ffffff",
            "SortOrder": null
        },
        /* {
          "BayStateId": 5,
          "BayStateName": "Out of Order",
          "FillColor": "#C9D6DE",
          "BorderColor": "#A6B4BF",
          "LabelColor": "#607279",
          "TextColor": "#607279",
          "SortOrder": null
        }, */
        ,
        {
            "BayStateId": "6",
            "BayStateName": "Offline",
            "FillColor": "#C9D6DE",
            "BorderColor": "#A6B4BF",
            "LabelColor": "#607279",
            "TextColor": "#607279",
            "SortOrder": null
        }
    ]


export const floorData = [
    { value: "1", label: "1st Floor" },
    { value: "2", label: "2nd Floor" },
    { value: "3", label: "3rd Floor" },

];

export const bayType = [
    { value: "1", label: "Standard Bays" },
    { value: "2", label: "VIP Bays" },
    { value: "3", label: "Suite" },

];

export const Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
]

export const RESPONSIVE_APP_LOGO = process.env.REACT_APP_RESPONSIVE_LOGO;


export const CUSTOM_DOMAIN = process.env.REACT_APP_CUSTOM_DOMAIN;
export const LOGOUT_COOKIE_NAME = process.env.REACT_APP_LOGOUT_COOKIE_NAME;