import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: [],
  draft: [],
  archive: [],
  reload: 0,
  
  
};



export const operatingHoursSlice = createSlice({
  name: 'operatingHours',
  initialState,
  reducers: {
    // actions
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setDraft: (state) => {
      state.draft = action.payload;
    },
    setArchive: (state) => {
      state.archive = action.payload;
    },

    deleteActive: (state, action) => {
      state.active = action.payload;
    },
    deleteDraft: (state) => {
      state.draft = action.payload;
    },
    deleteArchive: (state) => {
      state.archive = action.payload;
    },
    reloadOHTable: (state) => {
      state.reload = 1;
    },
    resetReloadOHTable: (state) => {
      state.reload = 0;
    }
  },

});

export const { setActive, setDraft, setArchive, deleteActive, deleteDraft, deleteArchive, reloadOHTable, resetReloadOHTable } = operatingHoursSlice.actions;


export default operatingHoursSlice.reducer;
