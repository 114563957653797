import { createSelector, createSlice } from "@reduxjs/toolkit";
import { batchActions } from "redux-batched-actions";

const initialState = {
  allValue: [],
  selectedBayFloors: [],
  checkedBayFloorCount: [],
  fullVenueBayCheck: false,
  bayCountByfloor: [],
  disabledBaysCount: {},
};
export const eventBaysSlice = createSlice({
  name: "eventBays",
  initialState,
  reducers: {
    addBays: (state, action) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((batchedAction) => {
          handleSingleAddBays(state, batchedAction);
        });
      } else {
        // Handle individual action
        handleSingleAddBays(state, action);
      }
    },
    removeAllBays: (state, action) => {
      return {
        ...state,
        allValue: [], // Create a new state object with allValue set to an empty array
      };
    },

    saveFloorCount: (state, action) => {
      state.bayCountByfloor = action.payload;
    },

    allValueChecked: (state, action) => {
      state.fullVenueBayCheck = action.payload;
    },

    selectedByFloorChecked: (state, action) => {
      state.selectedBayFloors = action.payload;
    },

    setAllFloorChecked: (state, action) => {
      const updatedObject = Object.fromEntries(
        Object.keys(action.payload).map((key) => [key, true])
      );
      state.selectedBayFloors = updatedObject;
      state.checkedBayFloorCount = state.bayCountByfloor;
    },
    setAllFloorUnChecked: (state, action) => {
      const updatedObject = Object.fromEntries(
        Object.keys(action.payload).map((key) => [key, false])
      );
      state.selectedBayFloors = updatedObject;
      state.checkedBayFloorCount = state.bayCountByfloor;
    },
    setFloorDisable: (state, action) => {
      const updatedObject = { ...state.disabledBaysCount };
      updatedObject[action.payload.floor] = true;
      state.disabledBaysCount = updatedObject;
      state.fullVenueBayCheck = false;
    },
    resetFloorDisable: (state) => {
      state.disabledBaysCount = {};
    },
    unCheckedFloorCheckBox: (state, action) => {
      state.selectedBayFloors = {
        ...state.selectedBayFloors,
        [action.payload]: false,
      };
    },

    redRntialCountOfCheckedBay: (state, action) => {
      state.checkedBayFloorCount = action.payload;
    },

    updateCheckedBayFloorCount: (state, action) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((batchAction) => {
          handleCheckedBayFloorCount(state, batchAction);
        });
      } else {
        // Handle individual action
        handleCheckedBayFloorCount(state, action);
      }
    },

    setSelectedFloorCheckek: (state, action) => {
      if (action.payload.action) {
        state.selectedBayFloors = {
          ...state.selectedBayFloors,
          [action.payload.floor]: true,
        };

        state.checkedBayFloorCount = {
          ...state.checkedBayFloorCount,
          [action.payload.floor]: state.bayCountByfloor[action.payload.floor],
        };
      } else {
        state.selectedBayFloors = {
          ...state.selectedBayFloors,
          [action.payload.floor]: false,
        };
        state.fullVenueBayCheck = false;

        state.checkedBayFloorCount = {
          ...state.checkedBayFloorCount,
          [action.payload.floor]: 0,
        };
      }
    },
  },
});
const handleSingleAddBays = (state, action) => {
  if (action.payload) {
    if (action.payload.checked) {
      if (state.allValue?.length > 0) {
        const bayList = state.allValue.map((item) => item.value);
        if (!bayList.includes(action.payload.value)) {
          state.allValue?.push(action.payload);
        }
      } else {
        state.allValue?.push(action.payload);
      }
    } else {
      state.allValue = state.allValue?.filter(
        (bay) => bay.value !== action.payload.value
      );
    }
    handleCheckboxSelect(state);
  }
};

const handleCheckedBayFloorCount = (state, batchAction) => {
  if (batchAction.payload.action == "remove") {
    state.checkedBayFloorCount = {
      ...state.checkedBayFloorCount,
      [batchAction.payload.floor]:
        state.checkedBayFloorCount[batchAction.payload.floor] - 1,
    };
  } else if (batchAction.payload.action == "add") {
    state.checkedBayFloorCount = {
      ...state.checkedBayFloorCount,
      [batchAction.payload.floor]:
        state.checkedBayFloorCount[batchAction.payload.floor] + 1,
    };

    if (
      state.checkedBayFloorCount[batchAction.payload.floor] ==
      state.bayCountByfloor[batchAction.payload.floor]
    ) {
      state.selectedBayFloors = {
        ...state.selectedBayFloors,
        [batchAction.payload.floor]: true,
      };
    }
  }
};

const handleCheckboxSelect = (state) => {
  const selectedBayFloors = { ...state.selectedBayFloors };
  // console.log("calling dont know why");
  let loopSuccessful = false;
  for (const key in selectedBayFloors) {
    loopSuccessful = true;
    if (selectedBayFloors.hasOwnProperty(key)) {
      const value = selectedBayFloors[key];
      if (value == false) {
        loopSuccessful = false;
        break;
      }
    }
  }
  if (loopSuccessful) {
    state.fullVenueBayCheck = true;
  }
};

const items = (state) => state.eventBays.allValue;
export const bayGuid = createSelector([items], (items) => {
  return items?.map((value) => {
    return value.value;
  });
});
export const bayNumber = createSelector([items], (items) => {
  const groupedBays = _.groupBy(items, function (b) {
    return b.floor;
  });
  const bayNames = {};
  Object.keys(groupedBays).map((floor) => {
    const tempArray = [];
    groupedBays[floor].map((bay) => {
      return tempArray.push(bay.bayName);
    });
    bayNames[floor] = tempArray;
  });
  return bayNames;
});

export const {
  addBays,
  removeAllBays,
  bayNamesArray,
  saveFloorCount,
  allValueChecked,
  selectedByFloorChecked,
  setAllFloorChecked,
  unCheckedFloorCheckBox,
  redRntialCountOfCheckedBay,
  updateCheckedBayFloorCount,
  setSelectedFloorCheckek,
  setAllFloorUnChecked,
  setFloorDisable,
  resetFloorDisable,
} = eventBaysSlice.actions;
export default eventBaysSlice.reducer;
