

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userValue:{}

};


export const userSlice = createSlice({
    name: 'userValues',
    initialState,
    reducers: {
        // actions
        setUserValues:(state,action)=>{
            state.userValue = action.payload
        }
      

    },

});

export const {setUserValues } = userSlice.actions;


export default userSlice.reducer;
