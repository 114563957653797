import { API_URL, API_URL_UPDATED, APP_GUID, APP_NAME, APP_VERSION, FLITE_API_AZURE, VENUE_GUID, } from "../constants";
import api from "./api";
import axios from "axios";

class DataService {
    app_info = {
        venue_guid: VENUE_GUID,
        app_name: APP_NAME,
        app_version: APP_VERSION,
        app_guid: APP_GUID,
    };

    /* Employee */

    async getGeneric(payload = {}) {
        return api.post(API_URL + "generic-module/get-generic", {
            request: payload,
            app_info: this.app_info,
        });
    }


    async getEmployee(payload = {}) {
        return api.post(
            API_URL_UPDATED + "employees-module/get-employees",
            payload
        );
    }

    async addEvent(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/insert-events", payload);
    }

    async getEvents(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/get-events", payload);
    }

    async updateEvent(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/update-events", payload);
    }

    async archiveEvent(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/archive-event", payload);
    }

    async getEventsBays(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/get-event-bays", payload);
    }
    async getEventsDetails(payload = {}) {
        return api.post(
            API_URL_UPDATED + "events-module/get-event-details",
            payload
        );
    }
    async getEventsAreas(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/get-event-areas", payload);
    }
    async AssignEventsAreas(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/assign-event-areas", payload);
    }
    async AssignEventsBays(payload = {}) {
        return api.post(API_URL_UPDATED + "events-module/assign-event-bays", payload);
    }

    async updateEmployee(payload = {}) {
        return api.post(API_URL_UPDATED + "employees-module/update-employee-avatar", payload);
    }

    async updateGeneric(payload = {}) {
        return api.post(API_URL + "generic-module/update-generic", {
            request: payload,
            app_info: this.app_info,
        });
    }


    async getAppAccess(payload = {}) {
        return api.post(API_URL_UPDATED + "appsecurity-module/get-user-access-for-all-apps", payload);
    }
    async getAppAccessList(payload = {}) {
        return api.post(API_URL_UPDATED + "appsecurity-module/get-all-apps", payload);
    }

    async getAppAccessSingle(payload = {}) {
        return api.post(API_URL_UPDATED + "appsecurity-module/get-app-access", payload);
    }


    async updateAppAccess(payload = {}) {
        return api.post(API_URL_UPDATED + "appsecurity-module/insert-or-update-app-access", payload);
    }
    async importEvents(payload = {}) {
        return api.get(API_URL_UPDATED + "events-module/import-events-from-triple-seat",{params: payload});
    }

    async getBays(payload = {}) {
        /* return api.post(API_URL + "bays-module/get-bays", {
          request: payload,
          app_info: this.app_info,
        }); */

        return api.post(API_URL_UPDATED + "bays-module/get-bays", payload);
    }

    async getEmployeeByRowGuid(payload = {}) {
        return api.post(
            API_URL_UPDATED + "employees-module/get-employee-details",
            payload
        );
    }

    async getAppAccessAwait(payload = {}) {
        const res = await api.post(
          API_URL_UPDATED + "appsecurity-module/get-app-access",
          payload
        );
        return res;
      }

      async findByEmail(payload = {}) {
        return api.post(
          API_URL_UPDATED + "/employees-module/find-by-email",
          payload
        );
      }

      async getAvatars(payload = {}) {
        return api.post(
          API_URL_UPDATED + "employees-module/get-avatars",
          payload
        );
      }

}

export default new DataService();
